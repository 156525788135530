import SectionLinks from "./SectionLinks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EpisodeRow from "./EpisodeRow";
import Section from ".//Section";
import "react-tabs/style/react-tabs.css";

export default function Program({
  program = null,
  newSeek,
  updateTime,
  streamProgress,
  streamPercent,
  duration,
  track,
  setTrack,
  playing,
  playAudio,
  buffering,
  profile,
  name,
}) {
  const links = program.links ? JSON.parse(program.links) : [];

  return (
    <div>
      {!name && program && program.name && (
        <h1 className="page-heading font-bold text-2xl md:text-4xl lg:text-5xl mb-8">
          {program.name}
        </h1>
      )}
      {program && (
        <div
          className="w-full gap-4 md:flex mb-8"
          to={`/program/${program.slug}`}
        >
          <div className="w-full md:w-1/5 md:max-w-1/5 mb-4">
            <img
              src={program.image.url}
              alt={program.name}
              className={"w-full h-auto"}
            />
          </div>
          <div className="md:flex-1 mb-4">
            {program && program.grid_one_liner && (
              <p className="text-lg font-bold">{program.grid_one_liner}</p>
            )}
            {program && program.introduction && (
              <p className="text-base mb-2">{program.introduction}</p>
            )}
            {program && program.presenter_string && (
              <p className="text-base mb-2">{program.presenter_string}</p>
            )}
            {program && program.bio && (
              <p
                itemProp="description"
                className="mb-8 mt-3 mb-3 "
                dangerouslySetInnerHTML={{
                  __html: program.bio
                    ? program.bio.split("\n").join("<br>")
                    : "",
                }}
              ></p>
            )}
            {links && <SectionLinks links={links} />}
          </div>
        </div>
      )}

      {/* {program && program.episodes && program.episodes.length > 0 && (
        <div className="py-4">
          <h2>Latest Episodes</h2>
          {program &&
            program.episodes &&
            program.episodes.map((episode, i) => (
              <EpisodeRow
                episode={{ ...episode, program: program }}
                program={program}
                key={`${i}e`}
                updateTime={updateTime}
                newSeek={newSeek}
                buffering={buffering}
                setTrack={setTrack}
                playing={playing}
                track={track}
                index={i}
                playAudio={playAudio}
                streamProgress={streamProgress}
                streamPercent={streamPercent}
                duration={duration}
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
              />
            ))}
        </div>
      )} */}
      {/* {program && program.sections && program.sections.length > 0 && (
        <div className="py-4">
          {program &&
            program.sections &&
            program.sections.map((section, i) => (
              <Section
                section={{ ...section, program: program }}
                updateTime={updateTime}
                newSeek={newSeek}
                buffering={buffering}
                setTrack={setTrack}
                playing={playing}
                track={track}
                index={i}
                playAudio={playAudio}
                streamProgress={streamProgress}
                streamPercent={streamPercent}
                duration={duration}
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
                key={`${i}s`}
              />
            ))}
        </div>
      )} */}
    </div>
  );
}
