import { ArrowCircleRightIcon, LinkIcon } from "@heroicons/react/solid";

export default function SectionLinks({ links, index = "blah" }) {
  return (
    <div className="section-links mt-4">
      {links.map((link, i) => (
        <div key={`${index}-${i}`}>
          <a
            className="flex items-center mb-2 gap-2 text-sm"
            href={link.url}
            referrerPolicy=""
            target="_blank"
          >
            {link.name} {link.description && ` - ${link.description}`}
            <ArrowCircleRightIcon className="w-4 h-4" />
          </a>
        </div>
      ))}
    </div>
  );
}
