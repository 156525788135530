import SectionLinks from "./SectionLinks";
import "react-tabs/style/react-tabs.css";
import Program from "./Program";
import moment from "moment";
import EpisodeRow from "./EpisodeRow";
import { useEffect, useState } from "react";
export default function Profile({
  profile = null,
  newSeek,
  updateTime,
  streamProgress,
  streamPercent,
  duration,
  track,
  setTrack,
  playing,
  playAudio,
  name,
  buffering,
}) {
  const now = moment().format("X");
  const links = profile.links ? JSON.parse(profile.links) : [];
  const [episodes, setEpisodes] = useState([]);
  useEffect(() => {
    const updateEpisodes = async () => {
      let temp = [];
      for (let index = 0; index < profile.programs.length; index++) {
        const p = profile.programs[index];
        temp = [...temp, ...p.episodes];
      }
      console.log("t", now, temp);
      setEpisodes(temp);
    };
    updateEpisodes();
  }, [profile]);
  return (
    <div>
      {name && (
        <h1 className="page-heading font-bold text-2xl md:text-4xl lg:text-5xl mb-8">
          {profile.public_name}
        </h1>
      )}
      {profile && (
        <div
          className="w-full gap-4 md:flex mb-2"
          // to={`/profile/${profile.slug}`}
        >
          <div className="w-full md:w-1/5 md:max-w-1/5 mb-4">
            <img
              src={profile.image.url}
              alt={profile.name}
              className={"w-full h-auto"}
            />
          </div>
          <div className="md:flex-1 mb-4">
            {profile && profile.bio && (
              <p
                itemProp="description"
                className="mb-8 mb-3  "
                dangerouslySetInnerHTML={{
                  __html: profile.bio
                    ? profile.bio.split("\n").join("<br>")
                    : "",
                }}
              ></p>
            )}
            {links && <SectionLinks links={links} />}
          </div>
        </div>
      )}

      {profile && profile.programs && profile.programs.length > 0 && (
        <div className="pb-4">
          <h2 className="text-xl mb-2 font-bold">
            Programs with {profile.public_name}
          </h2>
          {profile.programs.map((program, i) => (
            <Program
              key={`${i}-program`}
              name={program.name}
              program={program}
              profile={profile}
              updateTime={updateTime}
              newSeek={newSeek}
              buffering={buffering}
              index={i}
              setTrack={setTrack}
              playing={playing}
              track={track}
              playAudio={playAudio}
              streamProgress={streamProgress}
              streamPercent={streamPercent}
              duration={duration}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
            />
          ))}
        </div>
      )}
      {episodes && episodes.length > 0 && (
        <div className="py-4">
          <h2 className="text-lg font-bold mb-2">
            Latest Episodes with {profile.public_name}
          </h2>
          {episodes &&
            episodes.map((episode, i) =>
              episode.timestamp + episode.duration < now ? (
                <EpisodeRow
                  episode={{ ...episode, profile: profile }}
                  profile={profile}
                  key={`${i}e`}
                  updateTime={updateTime}
                  newSeek={newSeek}
                  buffering={buffering}
                  setTrack={setTrack}
                  playing={playing}
                  track={track}
                  index={i}
                  playAudio={playAudio}
                  streamProgress={streamProgress}
                  streamPercent={streamPercent}
                  duration={duration}
                  config={{
                    file: {
                      forceAudio: true,
                    },
                  }}
                />
              ) : (
                ""
              )
            )}
        </div>
      )}
      {/* {profile && profile.sections && profile.sections.length > 0 && (
        <div className="py-4">
          {profile &&
            profile.sections &&
            profile.sections.map((section, i) => (
              <Section
                section={{ ...section, profile: profile }}
                updateTime={updateTime}
                newSeek={newSeek}
                buffering={buffering}
                setTrack={setTrack}
                playing={playing}
                track={track}
                index={i}
                playAudio={playAudio}
                streamProgress={streamProgress}
                streamPercent={streamPercent}
                duration={duration}
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
                key={`${i}s`}
              />
            ))}
        </div>
      )} */}
    </div>
  );
}
